<template>
  <div class="search_warp">
    <Search @search="search" class="search" :searValue="keyword" />
    <div class="content">
      <van-tabs
        v-model="activeName"
        @change="changeTab"
        background="#ffffff"
        title-active-color="#ff678f"
        title-inactive-color="#666666"
        class="my_tabs"
        animated
        :swipeable="true"
      >
        <van-tab
          :title="item.name"
          v-for="(item, index) in tabs"
          :key="index"
          :name="item.path"
        >
          <keep-alive :include="include">
            <router-view
              v-if="activeName == item.path"
              class="content_scroll"
              :keyword="keyword"
              :key="key"
            />
          </keep-alive>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import Search from '@/components/Search';
import { Tab, Tabs } from 'vant';

export default {
  name: 'SearchDetails',
  components: {
    Search,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  data() {
    return {
      keyword: '',
      activeName: '',
      include: [
        'searchGraphices',
        'searchMovies',
        'searchTopic',
        'searchUser',
        'searchVideo',
      ],
      tabs: [
        {
          path: '/search/details/video',
          name: '抖音',
        },
        {
          path: '/search/details/graphices',
          name: '图文',
        },
        {
          path: '/search/details/movies',
          name: '影视',
        },
        {
          path: '/search/details/user',
          name: '用户',
        },
        {
          path: '/search/details/topic',
          name: '话题',
        },
      ],
    };
  },
  computed: {
    key() {
      return this.$route.fullPath + this.keyword;
    },
  },
  created() {
    this.keyword = this.$route.query.keyword;
  },
  methods: {
    changeTab(name) {
      this.$router.replace({
        path: name,
        query: { keyword: this.$route.query.keyword },
      });
    },
    search(val) {
      this.keyword = val;
      this.$bus.$emit('dataBuriedEvent', {
        dataType: 2,
        tagsName: val,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.my_tabs,
/deep/ .van-tabs__content,
/deep/ .van-tab__pane {
  height: 100%;
}

.search_warp {
  height: 100%;

  .content {
    height: 100%;

    .content_scroll {
      height: $searchDetailsHeight;
      //overflow-y: auto;
    }
  }

  /deep/ .van-tab__text {
    font-size: 18px;
  }
  /deep/ .van-tab {
    font-size: 16px;
  }

  /deep/ .van-tabs__line {
    bottom: 18px;
    width: 20px;
    height: 4px;
    border-radius: 2px;
    background: #ff678f;
  }
}
</style>
